import React from 'react'
import { Link } from 'react-router-dom'
const OurGoals = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li>  Academic   </li>
     <li> Our Goals </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Our Goals  </h1>
         </div>
     </div>
      <div className="row">
      <div className="col-lg-6">
      <h6>CHARACTER GOALS : </h6>
      <ul className="mainlist">
      <li> To develop faith, hope and love in God. </li>
      <li> To maintain discipline. </li>
      <li> To know the value of time. </li>
      <li> To develop self-respect. </li>
      <li> To set examples for others. </li>
      <li> To be honest, just, truthful and polite. </li>
      <li> To do one’s duty properly and sincerely </li>
      <li> To be clean in thoughts, words and deeds. </li>
      <li> To develop interests, skills and attitudes which contribute </li>
      <li> To the worthy use of leisure time. </li>
      <li> To be kind to sick, poor and marginalized. </li>
      
      </ul>
      </div>
      <div className="col-lg-6">
      
      <h6> ACADEMIC GOALS : </h6>
      <ul className="mainlist">
      <li> To have command over subject </li>
      <li> To develop feeling of love for learning. </li>
      <li> To be able to find out different ways of solving problems. </li>
      <li> To be able to view situations from many angles. </li>
      <li> To obtain mastery of communication skills. </li>
      <li> To develop a level of competency in English. </li>
      
      </ul>
      </div>
      <div className="col-lg-6">
      
      <h6> SOCIAL GOALS : </h6>
      <ul className="mainlist">
      <li> To have respect for all religions. </li>
      <li> To know the value of peace and non-violence. </li>
      <li> To have respect for all living beings. </li>
      <li> To make the world a safe, peaceful, pollution-free place to live in. </li>
      <li> To have respect for public property. </li>
      <li> Overall become a good and responsible citizen. </li>
      
      
      </ul>
      </div>
      <div className="col-lg-6">
      <h6> CULTURAL GOALS : </h6>
      <ul className="mainlist">
      <li> To be familiar with and have love for Indian culture and heritage. </li>
      <li> To gain knowledge of Indian rituals. </li>
      <li> To be familiar with the literature of other prominent religions. </li>
      </ul>
      </div>
      </div>
</div>
</div>
    </>
  )
}

export default OurGoals
