import React from 'react'
import { Link } from 'react-router-dom'
const AboutUs = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li> About Us </li>
     <li> About Us </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> About Us  </h1>
         </div>
     </div>
     <div className="row">
     <div className="col-lg-12">
       <h6>The Congregation Of The Sisters Of The Imitation Of Christ</h6>
            <p>The Congregation of the Sisters of the Imitation of Christ was founded in <b>1925</b> by
            Servant of God Archbishop Mar Ivanios. He was the soul founder of the Re-union
            Movement. The specific object of this Congregation is to continue the redemptive
            work of our Lord uninterrupted, to offer spiritual service to the people of India and
            to contribute to the spiritual renewal of the Malankara Catholic Church. We are
            running Hospitals, Schools, Orphanages, Home for the aged, training centres
            for the physically handicapped & leprosy eradication programmes, community
            development programmes, etc.</p>
            <p>The name <b>‘Bethany’ </b> helps to fulfil this obligation. Like Mary of Bethany, at the feet
            of Jesus lost in contemplation, like Martha, at the service of others and like Lazar
            the evangelist, spreading the Gospel. The Congregation is growing and flourishing
            in and outside Kerala. Motivated by our objectives, we as a Congregation try to
            live up our call to be with the Lord and to do His Will.</p>
            <br/>
            <h6>About The School</h6>
            
            <p><b>St. Mary’s Convent Sr. Sec. School </b> is a Catholic Institution started in <b>1993 </b>. It
            is directed and managed by Bethany Sisters and educationalists. The school is
            founded with a great vision to uplift the community and society not only intellectually
            but also spiritually and morally. We are guided by sublime principles of genuine
            education. Co-education, comprehensive curriculum, modern approaches in
            education, holistic formation and personal development are the basic thrust of
            the school.</p>
            </div>
            </div>
</div>
</div>
    </>
  )
}

export default AboutUs
