import React from 'react'
import { Link } from 'react-router-dom'
import { SlideshowLightbox } from 'lightbox.js-react'
 
const Infrastructure = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li> About Us </li>
     <li> Infrastructure </li>
 </ul>
</div>
<div className="innersec">
   <div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Infrastructure  </h1>
         </div>
     </div>
      <div className="row">
         <div class="col-lg-12">
         <div class="infrastructuretab">
         <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
           <button class="nav-link active" id="v-pills-bio-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bio" type="button" role="tab" aria-controls="v-pills-bio" aria-selected="true">Biology Lab</button>
           <button class="nav-link" id="v-pills-che-tab" data-bs-toggle="pill" data-bs-target="#v-pills-che" type="button" role="tab" aria-controls="v-pills-che" aria-selected="false">Chemistry Lab </button>
           <button class="nav-link" id="v-pills-phy-tab" data-bs-toggle="pill" data-bs-target="#v-pills-phy" type="button" role="tab" aria-controls="v-pills-phy" aria-selected="false">Physics Lab </button>
           <button class="nav-link" id="v-pills-com-tab" data-bs-toggle="pill" data-bs-target="#v-pills-com" type="button" role="tab" aria-controls="v-pills-com" aria-selected="false">Computer Lab  </button>
           <button class="nav-link" id="v-pills-lib-tab" data-bs-toggle="pill" data-bs-target="#v-pills-lib" type="button" role="tab" aria-controls="v-pills-lib" aria-selected="false">Library</button>
           <button class="nav-link" id="v-pills-background-tab" data-bs-toggle="pill" data-bs-target="#v-pills-background" type="button" role="tab" aria-controls="v-pills-background" aria-selected="false">Playground</button>
           <button class="nav-link" id="v-pills-transport-tab" data-bs-toggle="pill" data-bs-target="#v-pills-transport" type="button" role="tab" aria-controls="v-pills-transport" aria-selected="false">Transport</button>
         </div>
         <div class="tab-content" id="v-pills-tabContent">
           <div class="tab-pane fade show active" id="v-pills-bio" role="tabpanel" aria-labelledby="v-pills-bio-tab"> 
                 <div className="infrastructurebox">
                       <SlideshowLightbox>
                             <img src="/Images/bio.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                             <img src="/Images/bio1.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                             <img src="/Images/bio2.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
                        </SlideshowLightbox>
                 </div>
           </div>
           <div class="tab-pane fade" id="v-pills-che" role="tabpanel" aria-labelledby="v-pills-che-tab"> 
              <div className="infrastructurebox">
                   <SlideshowLightbox>
                         <img src="/Images/Chemistry.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                         <img src="/Images/Chemistry1.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                         <img src="/Images/Chemistry2.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
                         <img src="/Images/Chemistry3.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
                    </SlideshowLightbox>
             </div>
           </div>
           <div class="tab-pane fade" id="v-pills-phy" role="tabpanel" aria-labelledby="v-pills-phy-tab"> 
                <div className="infrastructurebox">
             <SlideshowLightbox>
                   <img src="/Images/phys.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                   <img src="/Images/phys1.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                   <img src="/Images/phys2.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
                   <img src="/Images/phys3.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
              </SlideshowLightbox>
       </div>
           </div>
           <div class="tab-pane fade" id="v-pills-com" role="tabpanel" aria-labelledby="v-pills-com-tab"> 
                 <div className="infrastructurebox">
                     <SlideshowLightbox>
                           <img src="/Images/Computer.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                           <img src="/Images/Computer1.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                           <img src="/Images/Computer2.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>   
                      </SlideshowLightbox>
               </div>
           </div>
           <div class="tab-pane fade" id="v-pills-lib" role="tabpanel" aria-labelledby="v-pills-lib-tab"> 
                 
               <div className="infrastructurebox">
                     <SlideshowLightbox>
                           <img src="/Images/Library.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                           <img src="/Images/Library1.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                           <img src="/Images/Library2.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>  
                      </SlideshowLightbox>
               </div>
           </div>
           <div class="tab-pane fade" id="v-pills-background" role="tabpanel" aria-labelledby="v-pills-background-tab"> <div className="infrastructurebox">
             <SlideshowLightbox>
                   <img src="/Images/Playground.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                   <img src="/Images/Playground1.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                   <img src="/Images/Playground2.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
                   <img src="/Images/Playground3.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
                   <img src="/Images/Playground4.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
                   <img src="/Images/Playground5.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
                   <img src="/Images/Playground6.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
              </SlideshowLightbox>
              </div>
       </div>
           <div class="tab-pane fade" id="v-pills-transport" role="tabpanel" aria-labelledby="v-pills-transport-tab"> 
               <div className="infrastructurebox">
                     <SlideshowLightbox>
                           <img src="/Images/transport.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                           <img src="/Images/transport1.jpg" className="img-fluid border-img w-full rounded" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>  
                      </SlideshowLightbox>
               </div>
           </div>
         </div>
       </div>
         </div>
       </div>
      </div>
   </div>
    </>
  )
}

export default Infrastructure
