import React from 'react'
import { Link } from 'react-router-dom'
const SchoolTiming = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li>  Academic   </li>
     <li>School Timing & Schedule </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1>  School Timing & Schedule </h1>
         </div>
     </div>
     <div className="row tablebg">
     <div className="col-lg-6">
        <h6> School Timing  </h6>
        <div className="table-responsive maintable">
        <table className="table">
           <thead>
                <tr>
                   <th>Seasons</th>
                   <th> Grades Nursery -III </th>
                   <th> Grades Iv – XII</th>
  
                 </tr>
           </thead>
           <tbody>
                  <tr>
                     <td>Summer  </td>
                     <td>07:55 AM – 12:10 PM </td>
                     <td>07:55 AM – 02:10 PM</td>
                 </tr>
                  <tr>
                     <td>Winter </td>
                     <td>08:25 AM – 12:10 PM </td>
                     <td>08:25 AM – 02:10 PM</td>
                 </tr>
           </tbody>
        </table>
     </div>
     <p>Reporting time at school for students have to be followed strictly. Reporting
  time is subject to change according to the season and parents are supposed to
  follow the directions through the circulars without fail.</p>
   <br/>
        <h6> Vacations  </h6>
        <div className="table-responsive maintable">
        <table className="table">
           <thead>
                  <tr>
                   <th>Break</th>
                   <th> Grades & Dates </th>
                 </tr>
           </thead>
           <tbody> 
                  <tr>
                 <td>  Summer Break  </td>
                 <td> Grades Nursery – VIII (May 26 – July 01, 2024) Grades IX – XII (June 07 – July 01, 2024)</td>
                 </tr>
                 <tr>
                 <td>  Autumn Break  </td>
                 <td>  Grades Nursery – XII (October 10 – October 13, 2024)</td>
                 </tr>
                 <tr>
                 <td>   Diwali Break </td>
                 <td>   Grades Nursery – XII (October 31 – November 03, 2024)</td>
                 </tr>
                 <tr>
                 <td>  Winter Break  </td>
                 <td>  Grades Nursery – XII (January 04 – January 13, 2025)</td>
                 </tr>
           </tbody>
        </table>
     </div>
  <p><b>There will be block classes for Grade IX-XII from 27 May to 6
  June 2024. Timings : 7.30 AM - 11:30 AM</b></p>
  <p><b> For the travel plans, parents are advised to confirm vacation
  dates from the school because vacation dates are subject to
  change as per exigency.</b></p>
  </div>
  <div className="col-lg-6">
        <h6> Parent Teacher Meeting (PTM) Schedule </h6>
        <div className="table-responsive maintable">
        <table className="table">
           <thead>
                <tr>
                   <th>Month</th>
                   <th> Date </th>
                   <th> Grades  </th>
  
                 </tr>
           </thead>
           <tbody>
                 <tr> <td> May  </td> <td>  May 18,  2024  </td> <td> PTM for Grades Nursery – V </td> </tr>
                 <tr>  <td> May  </td><td> May 25, 2024 </td> <td>  PTM for Grades VI – XII </td> </tr>
                 <tr> <td> August </td> <td>  August 03, 2024 </td> <td>  PTM for Grades Nursery – III, X & XII </td> </tr>
                 <tr><td> August </td>  <td> August 17, 2024 </td> <td>  PTM for Grades IV – IX & XI </td> </tr> 
                 <tr> <td> October </td> <td> October 05, 2024 </td> <td>  PTM for Grades Nursery – III </td> </tr>
                 <tr> <td> October </td><td> October 19, 2024 </td> <td>  PTM for Grades IV – XII </td> </tr>
                 <tr> <td> December</td> <td> December 07, 2024  </td> <td>  PTM for Grades X & XII </td> </tr>
                 <tr> <td> December</td><td> December 21, 2024  </td> <td>  PTM for Grades Nursery – III </td></tr>
                 <tr> <td> December</td><td> December 28, 2024  </td> <td>  PTM for Grades IV – XII </td></tr>
                 <tr> <td>  February  </td> <td>  February 01, 2025 </td> <td> Special PTM Nursery – III, IX and XI </td> </tr> 
                 <tr> <td>  February  </td> <td>  February 15, 2025 </td> <td> Special PTM IV – VIII </td> </tr> 
                 <tr> <td>  March </td> <td>   March 01, 2025 </td> <td> Result & PTM for Grades IX, XI </td> </tr> 
                 <tr> <td>  March </td> <td>   March 21, 2025 </td> <td> Result & PTM for Grades Nursery – III </td> </tr> 
                 <tr> <td>  March  </td> <td>  March 22, 2025 </td> <td> Result & PTM for Grades IV – VIII </td> </tr>
           </tbody>
        </table>
     </div>
    <p>We strongly recommend a healthy and adequate communication and
  co-operation between the parents and the teachers for the betterment of
  students. Therefore, the school will schedule PTM on the above dates to discuss
  the academics, human formation and other extra-curricular matters.</p>
  </div>
  </div>
</div>
</div>
    </>
  )
}

export default SchoolTiming
