import React from 'react'
import { Link } from 'react-router-dom'

function PreSchool() {
    return (
        <>
            <div className="preadmission">
                <Link to="/PreAdmission">
                    <div className="preadmissionimg">
                        <img src="/Images/preadmission.png" className="img-fluid" />
                    </div>
                    <h6>for ongoing session</h6>
                    <img src="/Images/arrowicn.png" className="img-fluid" />
                </Link>
            </div>


            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li> Pre School </li>

                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1> Pre School  </h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div class="contact-items">
                                <div class="row align-center">
                                    <div class="col-lg-6 left-item">
                                        <div class="info-items">
                                            <div class="item">
                                                <div class="icon">
                                                    <i class="bi bi-geo-alt-fill"></i>
                                                </div>
                                                <div class="info">
                                                    <h5>Location</h5>
                                                    <p>
                                                        <a href="#">Plot no. 405, Indraprastha (IP) Colony, Sector 30-33, Faridabad - 121003</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="item">
                                                <div class="icon">
                                                    <i class="bi bi-telephone-fill"></i>
                                                </div>
                                                <div class="info">
                                                    <h5>Make a Call</h5>
                                                    <p><a href="tel:0129-3558076">0129-3558076 / </a>, <a href="tel:8130068076">8130068076</a></p>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 left-item right-item">
                                        <div class="info-items">
                                            <div class="item">
                                                <div class="icon">
                                                    <i class="bi bi-envelope-fill"></i>
                                                </div>
                                                <div class="info">
                                                    <h5>Send a Mail</h5>
                                                    <p>
                                                        <a href="mailto:stmarysconventpreschool@gmail.com">stmarysconventpreschool@gmail.com</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PreSchool