import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SlideshowLightbox } from 'lightbox.js-react'
import { getSubGallery } from '../Service/Api';
const SubGallery = () => {

  
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");

  console.log(id)
  const [data, setData] = useState("");
  const [index, setIndex] = React.useState(-1);

  console.log(id);

  useEffect(() => {
    if(id){
      const getData = async () => {
        try {
          let subgalleryData = await getSubGallery(id);
          console.log(subgalleryData);
          setData(subgalleryData);
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }
 
  }, []);

  console.log(data);

  const slides = data?.attachments?.map(img => ({
    src: `https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`
  }));


  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li>   Gallery </li>
     <li>  Photo Gallery </li>
     <li> Sub Gallery </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
<div className="row">
<div className="col-lg-12">
      <h1>  Sub Gallery </h1>
 </div>
</div>
     <div className="row tabs-dynamic">
 <div className="col-md-6 col-xl-3">
     <div className="count-val">
         <p><Link to="/PhotoGallery"><i className="bi bi-arrow-left"></i> Go Back</Link>  </p>
     </div>
 </div>
 <div className="col-md-6 col-xl-3">
     <div className="month-selection">
     <p>
                  <span>Event Date:</span>{" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}
                </p>
     </div>
 </div>
 <div className="col-md-6 col-xl-3">
     <div className="month-selection">
     <p>
                  <span>Last Update:</span>{" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}
                </p>
     </div>
 </div>
 <div className="col-md-6 col-xl-3">
     <div className="searchBox">
     <p><span>No. Of Photos:</span>{data?.attachments?.length}</p>
     </div>
 </div>
</div>
<div className="row">
 <div className="col-md-12">
 <h4 className="gallery-title">{data.title}</h4>
              <p>{data.description} </p>
 </div>

 <div className="subgalleryimg">
  {data !== "" && data.attachments && data.attachments.length > 0 && (
    <SlideshowLightbox>
      {data.attachments.map((img, index) => (
        <img key={index} src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`} className="img-fluid border-img w-full rounded" alt="St. Mary's School" />
      ))}
    </SlideshowLightbox>
  )}
</div>

        

</div> 
</div>
</div>
    </>
  )
}

export default SubGallery
