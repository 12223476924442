import React from 'react'
import { Link } from 'react-router-dom'
const CBSEDisclosure = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li> 
     <li> CBSE Mandatory Disclosure </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> CBSE Mandatory Disclosure  </h1>
         </div>
     </div>
     <div className="row">
     <div class="col-lg-12"><h6>A: GENERAL INFORMATION</h6><div class="table-responsive maintable"><table class="table"><thead>
     <tr>
     <th width="10%" class="text-center">S.NO</th><th width="60%" class="text-center">INFORMATION</th><th width="30%" class="text-center">DETAILS</th></tr>
   </thead><tbody>
     <tr>
     <td>1 </td>
     <td>NAME OF THE SCHOOL </td>
   <td>St Mary's Convent Senior Secondary School</td>
   </tr>
   
   <tr>
     <td>2 </td>
     <td>AFFILIATION NO.(IF APPLICABLE) </td>
   <td>530368</td>
   </tr>
   
   <tr>
     <td>3 </td>
     <td>SCHOOL CODE (IF APPLICABLE) </td>
   <td>40347</td>
   </tr>
   
   <tr>
     <td>4 </td>
     <td>COMPLETE ADDRESS WITH PIN CODE</td>
   <td> Bethany Nagar, Baroli Bathola,Faridabad Haryana - 121004</td>
   </tr>
   
   <tr>
     <td>5</td>
     <td> PRINCIPAL NAME &amp; QUALIFICATION: </td>
   <td>Sr.Tomsy SIC</td>
   </tr>
   
   <tr>
     <td>6</td>
     <td> SCHOOL EMAIL ID</td>
   <td><a href="mailto:stmarysconventschool.1993@gmail.com">stmarysconventschool.1993@gmail.com</a></td>
   </tr>
   
   <tr>
     <td>7 </td>
     <td>CONTACT DETAILS (LANDLINE/MOBILE)</td>
   <td><a href="tel:+91 9717528625">+91 9717528625</a></td>
   </tr>
   </tbody></table></div><h6>B: DOCUMENTS AND INFORMATION</h6><div class="table-responsive maintable"><table class="table"><thead>
     <tr>
     <th width="10%" class="text-center">S.NO </th><th width="60%" class="text-center">DOCUMENTS/INFORMATION </th><th width="30%" class="text-center">UPLOAD DOCUMENTS</th></tr>
   </thead><tbody>
     <tr>
     <td>1 </td>
     <td>COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY </td>
   <td><a href="pdf/CBSE-AFFILIATION.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</a></td>
   </tr>
   
   <tr>
     <td>2 </td>
     <td>COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE </td>
   <td><a href="pdf/Society-Registration.pdf#toolbar=0&amp;navpanes=0" target="_blank">View </a></td>
   </tr>
   
   <tr>
     <td>3 </td>
     <td>COPY OF NO OBJECTION CETIFICATE (NOC) ISSUED </td>
     <td><a href="pdf/noc.pdf#toolbar=0&amp;navpanes=0" target="_blank">View </a></td>
   </tr>
   
   <tr>
     <td>4 </td>
     <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, AND ITS RENEWAL, IF APPLICABLE</td>
     <td><a href="pdf/RECOGNITION-Registration.pdf#toolbar=0&amp;navpanes=0" target="_blank">View </a></td>
   </tr>
   
   <tr>
     <td>5 </td>
     <td>COPY OF VALID  BUILDING CERTIFICATE </td>
   <td><a href="pdf/Structural-Safety.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</a></td>
   </tr>
   
   <tr>
     <td>6 </td>
     <td>COPY OF VALID FIRE SAFETY CERTIFICATE </td>
   <td><a href="pdf/FIRE-SAFETY.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</a></td>
   </tr>
   
   <tr>
     <td>7 </td>
     <td>COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION</td>
   <td><a href="#" target="_blank">View </a></td>
   </tr>
   
   <tr>
     <td>8 </td>
     <td>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</td>
   <td><a href="pdf/Drinking-Water.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</a></td>
   </tr>
   <tr>
     <td>8 </td>
     <td>  WATER CERTIFICATES</td>
   <td><a href="pdf/Water-certificate.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</a></td>
   </tr>
   </tbody></table></div><h6>C: RESULT AND ACADEMICS</h6><div class="table-responsive maintable"><table class="table"><thead>
     <tr>
     <th width="10%" class="text-center">S.NO. </th><th width="60%" class="text-center">S.NO. DOCUMENTS/INFORMATION </th><th width="30%" class="text-center">UPLOAD DOCUMENTS</th></tr>
   </thead><tbody>
     <tr>
     <td>1 </td>
     <td>FEE STRUCTURE OF THE SCHOOL</td>
   <td><a href="pdf/FEE-STRUCTURE.pdf#toolbar=0&amp;navpanes=0" target="_blank">View </a></td>
   </tr>
   
   <tr>
     <td>2 </td>
     <td>ANNUAL ACADEMIC CALENDER</td>
   <td><a href="pdf/Annual-Calender.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</a></td>
   </tr>
   
   <tr>
     <td>3 </td>
     <td>LIST OF SCHOOL MANAGEMENT COMMITTEE</td>
   <td><a href="pdf/MANAGEMENT-COMMITTEE.pdf#toolbar=0&amp;navpanes=0" target="_blank">View</a></td>
   </tr>
   
   <tr>
     <td>4 </td>
     <td>LIST OF PTA MEMBERS</td>
   <td><a href="#" target="_blank">View</a></td>
   </tr>
   
   <tr>
     <td>5 </td>
     <td>LAST THREE YEAR RESULT OF THE BOARD EXAMINATION</td>
   <td>CHECK BELOW</td>
   </tr>
   </tbody></table></div><h6>RESULT CLASS: X</h6><div class="table-responsive maintable"><table class="table"><thead>
     <tr>
     <th class="text-center">S.NO.</th><th class="text-center">YEAR</th><th class="text-center">NO. OF APPEARED STUDENTS</th><th class="text-center">NO. OF STUDENTS PASSED</th><th class="text-center">PASS PERCENTAGE</th><th class="text-center">REMARKS</th></tr>
   </thead><tbody>
     <tr>
     <td>1</td>
     <td>2024</td>
   <td>123</td>
   <td>123</td>
   <td>100 </td>
   <td>-</td>
   </tr>
   
   
   <tr>
     <td>2</td>
     <td>2023</td>
   <td>103</td>
   <td>103</td>
   <td>100 </td>
   <td>-</td>
   </tr>
   
   
   <tr>
     <td>3</td>
     <td>2022</td>
   <td></td>
   <td></td>
   <td>100</td>
   <td>-</td>
   </tr>
   </tbody></table></div><h6>RESULT CLASS: XII</h6><div class="table-responsive maintable"><table class="table"><thead>
     <tr>
     <th class="text-center">S.NO.</th><th class="text-center">YEAR</th><th class="text-center">NO. OF APPEARED STUDENTS</th><th class="text-center">NO. OF STUDENTS PASSED</th><th class="text-center">PASS PERCENTAGE</th><th class="text-center">REMARKS</th></tr>
   </thead><tbody>
     <tr>
     <td>1</td>
     <td>2024</td>
   <td>51</td>
   <td>51</td>
   <td>100</td>
   <td>-</td>
   </tr>
   
   
   <tr>
     <td>2</td>
     <td>2023</td>
   <td>107</td>
   <td>106</td>
   <td>99.06</td>
   <td>-</td>
   </tr>
   
   
   <tr>
     <td>3</td>
     <td>2022</td>
   <td></td>
   <td></td>
   <td>100</td>
   <td>-</td>
   </tr>
   </tbody></table></div><h6>D: STAFF (TEACHING)</h6><div class="table-responsive maintable"><table class="table"><thead>
     <tr>
     <th width="10%" class="text-center">S.NO. </th><th width="60%" class="text-center">INFORMATION </th><th width="30%" class="text-center">DETAILS</th></tr>
   </thead><tbody>
     <tr>
     <td>1</td>
     <td>PRINCIPAL</td>
   <td> 01</td>
   </tr>
   
   <tr>
     <td>2</td>
     <td>TOTAL NO. OF TEACHERS</td>
   <td>74</td>
   </tr>
   
   <tr>
     <td rowspan="3"></td>
     <td> PGT</td>
   <td>31</td>
   </tr>
   
   <tr>
     <td> TGT</td>
     <td>15</td>
   </tr>
   
   <tr>
     <td> PRT </td>
     <td>12</td>
   </tr>
   
   <tr>
     <td>3 </td>
     <td>TEACHERS SECTION RATIO </td>
   <td>30:1</td>
   </tr>
   
   <tr>
     <td>4 </td>
     <td>DETAILS OF SPECIAL EDUCATOR </td>
   <td> Reena Rana</td>
   </tr>
   
   <tr>
     <td>5 </td>
     <td> DETAILS OF COUNSELLOR AND WELLNESS TEACHER </td>
   <td> Reena Rana</td>
   </tr>
   </tbody></table></div><h6>E: SCHOOL INFRASTRUCTURE</h6><div class="table-responsive maintable"><table class="table"><thead>
     <tr>
     <th width="10%" class="text-center">S.NO. </th><th width="60%" class="text-center">INFORMATION </th><th width="30%" class="text-center">DETAILS</th></tr>
   </thead><tbody>
     <tr>
     <td>1</td>
     <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR) </td>
   <td>14163 sq. mtrs.</td>
   </tr>
   
   <tr>
     <td>2</td>
     <td> NO. AND SIZE OF THE CLASS ROOMS (IN SQUARE MTR)</td>
   <td>82&amp;45</td>
   </tr>
   
   <tr>
     <td>3</td>
     <td> NO.ANDSIZEOFLABORATORIESINCLUDING COMPUTER LABS (IN SQ MTR)</td>
   <td>6 &amp; 46</td>
   </tr>
   
   <tr>
     <td>4 </td>
     <td>INTERNET FACILITY</td>
   <td> YES</td>
   </tr>
   
   <tr>
     <td>5</td>
     <td> NO. OF GIRLS TOILETS</td>
   <td>30</td>
   </tr>
   
   <tr>
     <td>6</td>
     <td> NO. OF BOYS TOILETS</td>
   <td>30</td>
   </tr>
   
   <tr>
     <td>7</td>
     <td>LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL</td>
   <td><a href="https://www.youtube.com/watch?v=kRxxvYSh5kM" target="_blank"><b>https://www.youtube.com/watch?v=kRxxvYSh5kM</b></a></td>
   </tr>
   </tbody></table></div></div>
    </div>
</div>
</div>
    </>
  )
}

export default CBSEDisclosure
