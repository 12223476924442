import React from 'react'
import { Link } from 'react-router-dom'
const Admission = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li> Admission </li>
     <li>  Admission </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1>  Admission </h1>
         </div>
     </div>
     <div className="row">
     <div className="col-lg-12">
          <h6>Admission For Nursery</h6>

          <ul className="mainlist">

           <li>  Minimum age for admission of Nursery to L.K.G. is 3+ years as on April
         1st. A corresponding age limit is set for all other classes. </li>
        <li>  At the time of admission, parents should bring the original birth certificate,
         photo copy of birth certificate, Aadhar Card, Parivaar Pehchan Pathr (PPP),
         2 passport size photos and 1 stamp size photo. </li>
        <li>  No admission is confirmed until a child has passed a test in General Aptitude
         in main stream subjects such as English, Hindi, Science and Maths. </li>
      </ul>



   


<h6>  Admission For Class XI</h6>
<p>The school offers admission for Class-XI in the following streams.</p>
          

<div className="table-responsive maintable">
<table className="table">
<thead>
<tr style={{textAlign:"center"}}>
<th colspan="2"> Science </th>
<th rowspan="2">Commerce </th>
<th rowspan="2">Humanities</th>
</tr>
<tr style={{textAlign:"center"}}>
<th>Medical </th>
<th>Non-medical</th>
</tr>
</thead>
<tbody>

<tr> <td>  English </td> <td>  English  </td> <td> English </td> <td>  English  </td></tr>
<tr> <td> Physics  </td> <td> Physics </td> <td>  Economics </td> <td>  Economics  </td></tr>
<tr> <td>  Chemistry </td> <td>  Chemistry </td> <td>  Accountancy </td> <td>  History  </td></tr>
<tr> <td>  Biology/ Mathematics  </td> <td>     Computer Science   </td> <td>   Business Studies </td> <td>   Political Science  </td></tr>

<tr> <td>  Physical Education </td> <td>  Physical Education/ Mathematics  </td> <td>  Physical Education/ Mathematics   </td> <td>   Physical Education   </td></tr>
       

</tbody>
</table>
</div>
<p>Entrance test is compulsory for the students who are in class X and willing to
take admission in Class XI for different streams.</p>
<p>Streams will be alloted based on their performance.</p>
</div>

</div>
</div>
</div>
    </>
  )
}

export default Admission
