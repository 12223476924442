import React from 'react'
import { Link } from 'react-router-dom'
const ContactUs = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li> 
     <li> Contact Us </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Contact Us </h1>
         </div>
     </div>
     <div className="enquiryform">
     <div className="enquiryformimg">
           <div className="locationbox">
               <div className="locationdiv">
                    <div className="locationicon">
                        <i className="bi bi-geo-alt"></i>
                    </div>
                    <div className="locationdetails">
                        <h5>address</h5>
                        <p><Link to="https://maps.app.goo.gl/HEv4eP8GSoCEshi26" target="_blank">  Sector- 82, Bethany Nagar, Bathola, Faridabad- 121004</Link></p>
                    </div>
               </div>
               <div className="locationdiv">
                    <div className="locationicon">
                        <i className="bi bi-telephone"></i>
                    </div>
                    <div className="locationdetails">
                        <h5>Phone</h5>
                        <p><Link to="tel:+91 9717528625"> +91 9717528625, </Link> </p>
                    </div>
               </div>
               <div className="locationdiv">
                    <div className="locationicon">
                        <i className="bi bi-envelope"></i>
                    </div>
                    <div className="locationdetails">
                        <h5>Email</h5>
                        <p><Link to="mailto:stmarysconventschool.1993@gmail.com"> stmarysconventschool.1993@gmail.com</Link></p>
                    </div>
               </div>
           </div>
     </div>
     <div className="contactdiv">
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5571.465114973619!2d77.3498105152871!3d28.39501410141114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdce7f84d9c8f%3A0x52e40b2989fc93e0!2sSt.%20Mary&#39;s%20Convent%20Senior%20Secondary%20School%2C%20Bhatola%2CSector-82%2C%20Faridabad!5e0!3m2!1sen!2sin!4v1716981473502!5m2!1sen!2sin" width="100%" height="470" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
     </div>
 </div>
</div>
</div>
    </>
  )
}

export default ContactUs
