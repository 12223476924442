import React from 'react'
import { Link } from 'react-router-dom'
const FeeRules = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li> Admission  </li>
     <li> Fee Rules </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Fee Rules for Academic Year </h1>
         </div>
     </div>
     <div className="row">
         <div className="col-lg-12">
            <p>The fee due to school for Academic Session 2024-25 will be required to be paid
            either in four installments as detailed below or monthly - on or before the fifteenth
            day of every month:</p>
            
              <ul className="mainlist">
                <li> First Quarter April-June Fee to be paid within first fifteen days of May, 2024 </li>
              <li> Second Quarter July-September Fee to be paid within first fifteen days of July,
              2024 </li>
              <li> Third Quarter October-December Fee to be paid within first fifteen days of
              October 2024 </li>
              <li> Fourth Quarter January-March Fee to be paid within first fifteen days of January
              2025 </li>
            </ul>
            <p>You have the option to pay the fee on monthly mode also but it should be before
            15th of each month. </p>
            <p>Kindly note carefully the policy followed by school for late payment of fee.</p>
         </div>
     </div>
</div>
</div>
    </>
  )
}

export default FeeRules