import React from 'react'
import { Link } from 'react-router-dom'
const Discipline = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li>  Academic   </li>
     <li> Discipline </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Discipline </h1>
         </div>
     </div>
      <div className="row">
      <div className="col-lg-6">
      <h6>General Rules</h6>
      <ul className="mainlist">
                  <li> The school insists on punctuality, regular attendance, good manners,
                  cleanliness and devotion to study. Above all a student must be courteous
                  to teachers, elders and his/her fellow students. </li>
                  <li> Every student is required to keep the school premises neat and tidy.
                  Violation of this rule will be severely dealt with. </li>
              
      
                  <li> All assignments should be regularly done and submitted. </li>
                  <li> At the first bell, there should be perfect silence in the school premises. They
                  should pray silently and seek God’s blessings. </li>
                  <li> Students are strictly forbidden to bring objectionable literature/picture /
                  material or to purchase any article of food from an unauthorised dealer at
                  or near the school premises. </li>
                  <li> Money should not be lent or borrowed or article exchanged. </li>
                  <li> Policy of give respect and take respect should be followed. </li>
                  <li> Students are continuously monitored through CCTV Cameras. The school
                  building both inside and outside are equipped with these cameras, any
                  indisciplinary action will call for penalisation or suspension. </li>
      
      </ul>
      </div>
      <div className="col-lg-6">
      <h6>Library Rules</h6>
      <ul className="mainlist">
                   <li>  Students are advised to make the best use of Library. </li>
                   <li>  Strict silence should be maintained in the Library. </li>
                   <li>  Library Card issued should be kept safe until the session is over. </li>
                   <li>  Within seven days, the student should return the book issued otherwise
                  a fine of Rs. 50/- per week will be levied. In case of any book that goes
                  missing, cost of the book will be charged from students. </li>
                   <li>  Students found writing with pen/pencil in the library book, tearing,
                  scratching or mishandling of the book is liable to be penalised. </li>
              </ul>
      </div>
      </div>
</div>
</div>
    </>
  )
}

export default Discipline
