import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getTopper } from "../Service/Api";
const HomeTopperXII = () => {
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getTopper();
        setData(topperData);
      } catch (error) {
        console.error("Error fetching topper data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("topper_data", data);
    console.log("topper_data", data.length + "test");
  }, [data]);

  const emptyArray = [
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/topper.jpg",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/topper.jpg",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/topper.jpg",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/topper.jpg",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/topper.jpg",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments: "Images/topper.jpg",
    },
  ];

  return (
    <>
      <div className="row">
        <Slider {...settings}>
          {data.filter((item) => item.class === "XII").length > 0
            ? data
                .filter((item) => item.class === "XII")
                .map((item, index) => (
                  <div className="item" key={index}>
                    <div className="topperdiv">
                      <div className="topperimages">
                        <img
                          src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`}
                          className="img-fluid"
                        />
                      </div>
                      <img
                        src="/Images/tooperpicicn.png"
                        className="img-fluid tooperpicicn"
                      />
                      <div className="topperdesc">
                        <p className="per">{item.division}</p>
                        <p className="name">{item.name} </p>
                      </div>
                    </div>
                  </div>
                ))
            : emptyArray.map((data, index) => (
                <div className="item" key={index}>
                  <div className="topperdiv">
                    <div className="topperimages">
                      <img src={data.attachments} className="img-fluid" />
                    </div>
                    <img
                      src="/Images/tooperpicicn.png"
                      className="img-fluid tooperpicicn"
                    />
                    <div className="topperdesc">
                      <p className="per">{data.division}</p>
                      <p className="name">{data.name} </p>
                    </div>
                  </div>
                </div>
              ))}
        </Slider>
      </div>
    </>
  );
};

export default HomeTopperXII;
