import React from 'react'
import OwlCarousel from 'react-owl-carousel';
const HomeMessage = () => {
  const options = {
    margin: 30,
    dots:false,
    nav: true,
    navText : ["<i class='bi bi-arrow-left'></i>","<i class='bi bi-arrow-right'></i>"],
    responsiveClass: true,
    autoplay: true,
    smartSpeed: 1000,
    items: 1
};
  return (
     <> 
     <div className="row">
     <OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
     <div className="item">
          
      </div>
      <div className="item">
       
      </div>
      <div className="item">
      
      </div>
        </OwlCarousel> 
     </div>
     </>
  )
}

export default HomeMessage