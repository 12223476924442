import React from 'react'
import { Link } from 'react-router-dom'
const SchoolUniform = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li>Academic  </li>
     <li> School Uniform </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> School Uniform  </h1>
         </div>
     </div>
     
<div className="row tablebg">
<div className="col-lg-6">
    <h6>Summer (April To October)</h6>
<div className="table-responsive maintable">
   <table className="table">
    <tbody>
<tr>   <td> <b>  Boys (Nursery, LKG and UKG):  </b> Sea Green Half Sleeve Shirts with Grey
Shorts, School Belt, School Socks and Black Velcro Shoes. Black Patka for Sikh
boys. </td></tr>

<tr>   <td><b>  Girls (Nursery, LKG and UKG): </b> Sea Green Half Sleeve Shirts and Grey
Tunics; School Socks and Black Velcro Shoes. Only black ribbon, black rubber
band and black hairband are allowed. </td></tr>

<tr>   <td> <b> Boys (I TO XII): </b> Sea Green Half Sleeve Shirts with Grey Pants and Waist Coat,
School Belt, School Socks and Black Velcro Shoes. Black Patka for Sikh boys. </td></tr>

<tr>   <td> <b> Girls (I to V): </b> Sea Green Half Sleeve Shirts and Grey Tunics; School Socks and
Black Velcro Shoes. </td></tr>

<tr>   <td> <b> Girls (VI to XII): </b> Sea Green Half Sleeve Shirts with Grey Pants and Waist
Coat, School Socks and Black Velcro Shoes. Only black ribbon, black rubber
band and black hairband are allowed. </td></tr>
</tbody>
</table>
</div>
</div>
<div className="col-lg-6">
    <h6>Winter (November To March)</h6>
<div className="table-responsive maintable">
   <table className="table">
    <tbody>
<tr> <td> <b>Boys (Nursery, LKG and UKG):</b>  Sea Green full Sleeve Shirts with Grey
Pants, School Belt, Hood Jacket with school monogram, School Socks and Black
Velcro Shoes. Black Patka for Sikh boys and black cap for other boys. </td></tr>

<tr> <td><b> Girls : (Nursery, LKG and UKG):</b>  Sea Green Full Sleeve Shirts with Grey
Tunics; School Socks and Black Velcro Shoes. Only black ribbon, black rubber
band, black hairband and black cap are allowed. </td></tr>

<tr> <td> <b> Girls (I to V):</b>  Sea Green full Sleeve Shirts with Grey Tunics, Blazer, School
Socks and Black Velcro Shoes. Only black ribbon, black rubber band, black
hairband and black cap are allowed. </td></tr>

<tr> <td><b>  Boys (I TO XII): </b> Sea Green full Sleeve Shirts with Grey Pants, School Belt and
Waist Coat, Blazer, School Socks and Black Velcro Shoes. Black Patka for Sikh
boys and black cap for other boys if needed. </td></tr>

<tr> <td><b>  Girls (VI to XII):</b>  Sea Green full Sleeve Shirts with Grey Pants and Waist Coat,
Blazer, School Socks and Black Velcro Shoes. </td></tr>
</tbody>
</table>
</div>
</div>
</div>
<div className="row tablebg">
<div className="col-lg-12">
<h6>House Uniform</h6>
<div className="table-responsive maintable">
<table className="table">
<thead>
<tr>
<th colspan="2" style={{textAlign:"center"}}>Wednesday & Saturday</th>
</tr>
</thead>
<tbody>
<tr> <td>  St. Mary’s </td> <td>  Blue T-Shirt + White Tracks + School Socks and Black Velcro
Shoes. </td> </tr>
<tr> <td>  St. Joseph’s  </td> <td> Orange T-Shirt + White Tracks + School Socks and Black Velcro
Shoes. </td> </tr>
<tr> <td>  St. Xavier’s </td> <td>  Red T-Shirt + white Tracks + School Socks and Black Velcro
Shoes. </td> </tr>
<tr> <td>  St. Thomas’  </td> <td> Green T-Shirt + White Tracks + School Socks and Black Velcro
Shoes. </td> </tr>

</tbody>
</table>
</div>
</div>

</div>
<p>From 1st Nov. to 31st March, students have to wear tracksuit with house T – Shirt.</p>
 <p><b>Note : </b> There will be no house uniform for classes Nursery to
UKG.</p>
 <p>By wearing the school uniform daily, we wish to reinforce oneness and equality
amongst all students while encouraging them to respect each other and share
a special bonding as they learn to grow together.</p>
</div>
</div>
    </>
  )
}

export default SchoolUniform
