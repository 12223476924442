import React from 'react'
import { Link } from 'react-router-dom'
const PrincipalMessage = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li> About Us </li>
     <li> Principal's Message    </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
<div className="row">
    <div className="col-lg-12">
        <h1>Principal's Message</h1>
    </div>
</div>
<div className="row">
    <div className="col-lg-12">
        <div className="msgbox">
            <img src='/Images/principal.jpg' alt="St. Mary’s Convent Sr. Sec. School, Tigaon" className="img-fluid"/>
        </div>
        <p>Dear Parents and Students of St. Mary's Convent School, Faridabad,</p>
        <p>As we embark on the academic year 2024-2025, it is with great pride and enthusiasm that I extend my warmest greetings to each and every one of you. Here at St. Mary's Convent School, we are not just a community of learners, but a family bound together by the shared values of excellence, integrity, and compassion.</p> 
        <p>Parents, I invite you to take a moment to reflect on the privilege it is to be entrusted with the education and upbringing of such remarkable young individuals. Your decision to enroll your children in St. Mary's Convent School speaks volumes about your commitment to their future and your belief in the transformative power of education. Let us carry this pride with us as we journey through the academic year ahead.</p>
        <p>Students, you are the heart and soul of our school. Your curiosity, creativity, and determination inspire us every day. As you flip through the pages of this Almanac, remember that each entry represents an opportunity for growth, learning, and achievement. Embrace the challenges, celebrate the successes, and never forget the incredible potential that lies within each of you.</p> 
        <p>It is imperative that both parents and students familiarize themselves with the contents of this Almanac. Here, you will find important information, guidelines, and regulations that will help ensure a safe, nurturing, and enriching learning environment for all. Let us commit ourselves wholeheartedly to adhering to these regulations, not just for the sake of compliance, but out of respect for ourselves, our peers, and our community.</p> 
        <p>As we navigate the year ahead, let us do so with a sense of purpose, unity, and optimism. Together, we can overcome any obstacle, scale any height, and achieve any dream. Let us seize every opportunity to learn, to grow, and to make a positive difference in the world around us.</p> <p>In closing, I would like to express my deepest gratitude to each member of the St. Mary's Convent School family – parents, students, teachers, and staff – for your unwavering dedication and support. May this academic year be filled with joy, success, and endless possibilities.</p>
        <p>With warm regards,</p>
<p className="name">Sr. Tomsy SIC <span>

Principal</span></p>
    </div>
</div> 
</div>
</div>
    </>
  )
}

export default PrincipalMessage

