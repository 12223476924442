import React from 'react'
import { Link } from 'react-router-dom'
const ModeOfPayment = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li> Admission  </li>
     <li> Mode Of Payment </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Mode Of Payment </h1>
         </div>
     </div>
     <div className="row">
         <div className="col-lg-12">
                    <h6> Online Fee Payment Process Via Portal: </h6>
                    <ul className="mainlist">
                    <li>  Visit Https://entab.online/SMCTHR and log in with your
                credentials. </li>
                <li>  Select Pay Online from the left menu. </li>
                <li>  Choose the installment and click Proceed to Pay. </li>
                <li>  Hit Pay now, then pick your payment method (Debit/Credit cards,
                Internet Banking, UPI/QR, Wallet). </li>
                <li>  After payment, you're redirected to download the receipt from the
                Fee section. </li>
                </ul>
                
                <h6> Online Fee Payment Process Via Mobile App: </h6>
                    <ul className="mainlist">
                <li> Download App: <br/>
                iOS: CampusCare10X on App Store: - https://apps.apple.com/in/app/
                campuscare-10x/id1611283814 <br/>
                Android: CampusCare10X on Google Play- https://play.google.com/
                store/apps/details?id=com.entab.learninglab </li>
                <li> Install and open the CampusCare10X app. Enter the school code:
                SMCTHR </li>
                <li> Log in successfully, then tap on the Fees icon.</li>
                <li> Choose the installment and press Proceed to Pay.</li>
                <li> Select Pay now and your payment method.</li>
                <li> Post-payment, the app redirects you for receipt download.</li>
                
                </ul>
                
                <h6> For online payment QR CODE facility is also available. </h6>
                <div className="row qrcodelink">
                   <div className="col-lg-4 col-md-4 col-sm-6">
                        <p>St Mary's Convent Sr Secondary School</p>
                        <img src="/Images/qrcode1.jpg" className="img-fluid" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
                   </div>
                   <div className="col-lg-4 col-md-4 col-sm-6">
                      <p>St Mary's Convent  Pre Primary School</p>
                       <img src="/Images/qrcode2.jpg" className="img-fluid" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                   </div>
                   <div className="col-lg-4 col-md-4 col-sm-6">
                      <p>St Mary's Convent  Pre School</p>
                       <img src="/Images/qrcode3.jpg" className="img-fluid" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                   </div>
                </div>
                <h6>    For online payment please click on the url:</h6>
                <p> https://entab.online/SMCTHR</p>
                <ul className="mainlist">
                 <li>  In case you do not have your login credentials then kindly send the
                WhatsApp message along with name, class & admission number
                of your child to 8527923222. </li>
                 <li>  Click on the FEE option on green ribbon then select option ‘Online
                Fee Payment’. </li>
                 <li>  Select Quarter and all dues will appear </li>
                 <li>  Click on the Proceed Button. </li>
                 <li>  Make Payment through Debit Card/Credit Card/Net Banking. </li>
                 <li>  On successful payment Fee receipt will be generated. You will be
                redirected payment gateway, select your bank, and pay the fee. </li>
              </ul>
              <div className="table-responsive maintable">
<table className="table">
<thead>
<tr style={{textAlign:"center"}}>
<th>TIME (PERIOD BETWEEN) </th>
<th>LATE FEE</th>
</tr>
</thead>
<tbody>

<tr> <td> 1-15 days after expiry of due date  </td>
     <td>  100/-  </td> 
</tr>



<tr> <td>Next 15 days </td>
     <td>  500/-  </td> 
</tr>


<tr> <td>Above 30 days from due date </td>
     <td>  Name of child to be struck off from school &
re-admission shall be considered solely at the
discretion of the management  </td> 
</tr>
</tbody>
</table>
</div>
         </div>
     </div>
</div>
</div>
    </>
  )
}

export default ModeOfPayment