import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getVideo } from '../Service/Api';

const VideoGallery = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [year, setYear] = useState([]);

  

  useEffect(() => {
    const fetchData = async () => {
      const galleryData = await getVideo();
      setData(galleryData);
      const uniqueYears = Array.from(
        new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
      );
      uniqueYears.sort((a, b) => b - a);
      setYear(uniqueYears);
      console.log(galleryData);
      setFilteredData(galleryData); // Initialize filteredData with all data
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data);
  }, [data]);

  
  const emptyArray = [
    { title: "Video Title", date: "DD/MM/YYYY"},   
    { title: "Video Title", date: "DD/MM/YYYY"},   
    { title: "Video Title", date: "DD/MM/YYYY"},   
  ];
  useEffect(() => {
    // Filter data based on selected year
    if (selectedYear && selectedYear !== "All") {
      const filteredData = data.filter((item) => {
        const itemYear = new Date(item.date).getFullYear();
        return itemYear === parseInt(selectedYear);
      });
      setFilteredData(filteredData);
    } else {
      // If selected year is "All" or not selected, set filterData to all data
      setFilteredData(data);
    }
  }, [selectedYear, data]); // Added 'data' as a dependency
  ; // Added 'data' as a dependency
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];


  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/home"> Home</Link> </li>
          <li>  Gallery </li>
          <li> Video Gallery </li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row tabs-dynamic">
            <div className="col-lg-6">
              <h1> Video Gallery </h1>
            </div>
            <div className="col-lg-6">
              <div className="chooseyear">
              <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection"
                >
                  <option value={"All"}>All</option>
                  {year.map((yr) => (
                    <option value={yr}>{yr}</option>
                  ))}
                  {/* <option value={2022}>2022</option>
                  <option value={2021}>2021</option>
                  <option value={2020}>2020</option>
                  <option value={2019}>2019</option>
                  <option value={2018}>2018</option> */}
                  {/* Add more years if necessary */}
                </select>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div id="showTwo" className="myDiv1 selected_year">
            <div className="row">
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="video-gallery">
                      <Link to={item.videoUrl} target="_blank">
                        <i className="bi bi-play-circle-fill"></i>
                        <div>
                          <h3>{item.title}</h3>
                          <p>{new Date(item.date).toLocaleDateString('en-GB')}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                emptyArray.map((data, index) => (
                  <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="video-gallery">
                      <Link to="#" target="_blank">
                        <i className="bi bi-play-circle-fill"></i>
                        <div>
                          <h3>{data.title}</h3>
                          <p>{data.date}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoGallery;
